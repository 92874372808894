import React from "react";
import { PageProps, PageThemeConfig } from "types";
import { Nav } from "components/nav";
import { DeepRequired } from "ts-essentials";
import { Page } from "components/page";

const themeConfig: DeepRequired<PageThemeConfig> = {
  dark: {
    background: "bg-indigo-900",
    textOnBackground: "text-white",
  },
  light: {
    background: "bg-purple-100",
    textOnBackground: "text-gray-900",
  },
};

const MusicPage: React.FC<PageProps> = () => {
  return (
    <Page
      title="Music"
      description="Original music composed and recorded by Michael Hueter"
      themeConfig={themeConfig}
    >
      <Nav />
      <h1 className="text-white text-center">Coming soon...</h1>
    </Page>
  );
};

export default MusicPage;
